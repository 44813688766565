<template>
    <v-dialog v-model="show" persistent max-width="600">
        <ToolBarModal />
        <v-card class="fill-height pt-2 pb-2" fluid>
            <v-container class="text-center">

                <div v-if="resentEmail == true">
                    <v-alert text color="success" class="mx-12" border="left">
                        <h3 class="text-h5 font-weight-bold">E-mail enviado com sucesso.</h3>
                        <div>Enviamos um link para seu e-mail, entre e clique no link para podermos recriar sua senha. <br><br>Lembre de olhar a caixa de
                            <b>SPAM.</b>
                        </div>
                    </v-alert>
                    <router-link :to="{ path: '/' }" tag="v-btn">
                        <v-btn color="primary" width="25%" class="ma-2">
                            Fechar
                        </v-btn>
                    </router-link>
                </div>
                <div v-else-if="this.input.token" @submit.prevent="changePassword">

                    <v-alert text color="error" border="left">
                        <h3 class="text-h5 font-weight-bold">Vamos recriar a senha?</h3>
                        <div>Por questões de segurança não sabemos sua senha, será necessário recriar-la.</div>
                    </v-alert>

                    <v-form ref="formStep2" v-model="valid">
                        <v-text-field v-model="input.email" label="E-mail" disabled />
                        <v-text-field label="Digite a senha" v-model="input.password" min="8" :rules="[rules.required, rules.password]"
                            :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" counter required />
                        <v-text-field label="Confirme a senha" v-model="confirmPassword" min="8" :rules="confirmPasswordRules"
                            :append-icon="show2 ? 'visibility' : 'visibility_off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" counter required />

                        <v-switch v-model="keep" inset label="Manter logado" />

                        <v-btn color="success" width="25%" @click="changePassword" :loading="saving" :disabled="!valid">Enviar</v-btn>

                        <router-link :to="{ path: '/' }" tag="v-btn">
                            <v-btn color="primary" width="25%" class="ma-2">
                                Fechar
                            </v-btn>
                        </router-link>
                    </v-form>
                </div>
                <v-container v-else>
                    <v-row dense>
                        <v-col cols="12">
                            <v-card color="primary" dark>
                                <v-card-title class="headline">Infelizmente o link está vencido.</v-card-title>

                                <v-card-subtitle>Para sua segurança o link de acesso tem validade de 30 minutos. Para recriar a senha clique 'Reenviar' e verifique novamente seu
                                    e-mail.</v-card-subtitle>

                                <v-card-actions>
                                    <v-btn color="success" @click="sendLink" :loading="saving">Reenviar</v-btn>
                                </v-card-actions>
                            </v-card>
                            <v-progress-linear v-if="saving" indeterminate />
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import jwt from "@/helpers/jwt";
import rules from "@/helpers/rules";

import ToolBarModal from "@/components/core/ToolBarModal.vue";

export default {
    components: { ToolBarModal },

    mounted() {
        this.$store.commit("CLEAR_LOGIN");
        if (this.$route.params.token) {
            this.input.email = jwt.getEmail(this.$route.params.token);

            if (jwt.checkToken(this.$route.params.token)) {
                this.input.token = this.$route.params.token;
            }
        }
    },

    data() {
        return {
            jwt,
            rules,
            valid: true,
            show: true,
            saving: false,
            confirmPassword: null,
            show1: false,
            show2: false,
            keep: true,
            input: {
                email: null,
                password: null,
                token: null,
            },
            confirmPasswordRules: [(v) => v === this.input.password || "Senha diferentes"],
            resentEmail: false,
        };
    },

    methods: {
        sendLink() {
            this.saving = true;
            this.$http
                .post("api/v2/request-recovery", { email: this.input.email })
                .then(() => {
                    this.saving = false;
                    this.resentEmail = true;
                    // this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Verifique seu email.");
                })
                .catch((error) => {
                    this.saving = false;
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                });
        },
        changePassword() {
            if (this.$refs.formStep2.validate()) {
                this.saving = true;
                this.$http
                    .post("api/v2/recovery", this.input)
                    .then((result) => {
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Cadastro concluído com sucesso.");
                        this.forceLogin();
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                    });
            }
        },
        forceLogin() {
            this.saving = true;
            this.$http
                .post("api/v2/auth", this.input)
                .then((response) => {
                    this.$store.commit("SET_LOGIN", { response, email: this.input.email, remember: this.keep, password: this.keep ? this.input.password : null });
                    this.$emit("login");
                    this.$router.replace({ path: this.$store.state.routeBeforeLogin ? this.$store.state.routeBeforeLogin : "/" });
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Usuário e/ou Senha incorretos.");
                    this.$router.replace({ path: "/login" });
                });
        },
    },
};
</script>